import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Link} from 'react-router-dom';

const Content = () => (
  <div className="AppBodyContainer">
    <div className="AppHeader">
      <div
        style={{
          position: 'relative',
          width: '50%',
          paddingTop: '15vh',
          backgroundImage:
            'url(https://s3.eu-central-1.amazonaws.com/opticunion/images/fabema_logo.png)',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'left center'
        }}
      />
      <div className="AppHeadingWrapper">
        <span className="AppHeading">Kollektionsgestaltung</span>
        <span className="AppHeading">Entwicklung</span>
        <span className="AppHeading">Produktionslösungen</span>
        <span className="AppHeading">für den augenoptischen Mittelstand</span>
      </div>
    </div>
    <div className="Section">
      <div className="SectionContent">
        <img
          height="100%"
          src="https://s3.eu-central-1.amazonaws.com/opticunion/images/fabema_placeholder.jpg"
        />
        <div className="Contact">
          <span className="ContactText">
            Kontakt:{' '}
            <a href="mailto:mail@fabemacolor.de">mail@fabemacolor.de</a>
          </span>
        </div>
      </div>
    </div>
  </div>
);

const Imprint = () => (
  <div className="Imprint">
    <h3 className="ImpressumHeading">Impressum</h3>
    <span className="ImpressumText">FABEMA COLOR GmbH</span>
    <span className="ImpressumText">Holderäckerstr. 5</span>
    <span className="ImpressumText">D-70839 Gerlingen</span>
    <br />
    <span className="ImpressumText">Amtsgericht Stuttgart HRB 201285</span>
    <span className="ImpressumText">Geschäftsführer: Ralf Kittler</span>
    <span className="ImpressumText">USt.-Ident.Nr.: DE 146023537</span>
    <br />
    <p className="ImpressumText">
      Die Inhalte dieser Seiten werden mit größter Sorgfalt erstellt. Für
      Richtigkeit, Vollständigkeit und Aktualität dieser Inhalte kann jedoch
      keine Haftung übernommen werden. Schon die Tatsache, dass sich das Recht
      und Technologien weiterentwickeln führ dazu, dass Informationen nach
      einiger Zeit veraltet, unrichtig oder widersprüchlich sein können.
    </p>
    <br />
    <h3 className="SectionHeading">Datenschutz</h3>
    <span
      style={{
        textDecoration: 'underline',
        paddingBottom: '8px'
      }}
      className="Text"
    >
      1. Inhalt des Onlineangebots
    </span>
    <p className="ImpressumText">
      Der Autor übernimmt keinerlei Gewähr für die Aktualität, Korrektheit,
      Vollständigkeit oder Qualität der bereitgestellten Informationen.
      Haftungsansprüche gegen den Autor, welche sich auf Schäden materieller
      oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der
      dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und
      unvollständiger Informationen verursacht wurden, sind grundsätzlich
      ausgeschlossen, sofern seitens des Autors kein nachweislich vorsätzliches
      oder grob fahrlässiges Verschulden vorliegt. Alle Angebote sind
      freibleibend und unverbindlich. Der Autor behält es sich ausdrücklich vor,
      Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu
      verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise
      oder endgültig einzustellen.
    </p>
    <br />
    <span
      style={{
        textDecoration: 'underline',
        paddingBottom: '8px'
      }}
      className="Text"
    >
      2. Verweise und Links
    </span>
    <p className="ImpressumText">
      Bei direkten oder indirekten Verweisen auf fremde Internetseiten
      („Links“), die außerhalb des Verantwortungsbereiches des Autors liegen,
      würde eine Haftungsverpflichtung ausschließlich in dem Fall in Kraft
      treten, in dem der Autor von den Inhalten Kenntnis hat und es ihm
      technisch möglich und zumutbar wäre, die Nutzung im Falle rechtswidriger
      Inhalte zu verhindern. Der Autor erklärt hiermit ausdrücklich, dass zum
      Zeitpunkt der Linksetzung keine illegalen Inhalte auf den zu verlinkenden
      Seiten erkennbar waren. Auf die aktuelle und zukünftige Gestaltung, die
      Inhalte oder die Urheberschaft der gelinkten/verknüpften Seiten hat der
      Autor keinerlei Einfluss. Deshalb distanziert er sich hiermit ausdrücklich
      von allen Inhalten aller gelinkten /verknüpften Seiten, die nach der
      Linksetzung verändert wurden. Diese Feststellung gilt für alle innerhalb
      des eigenen Internetangebotes gesetzten Links und Verweise sowie für
      Fremdeinträge in vom Autor eingerichteten Gästebüchern, Diskussionsforen
      und Mailinglisten. Für illegale, fehlerhafte oder unvollständige Inhalte
      und insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung
      solcherart dargebotener Informationen entstehen, haftet allein der
      Anbieter der Seite, auf welche verwiesen wurde, nicht derjenige, der über
      Links auf die jeweilige Veröffentlichung lediglich verweist.
    </p>
    <br />
    <span
      style={{
        textDecoration: 'underline',
        paddingBottom: '8px'
      }}
      className="Text"
    >
      3. Urheber- und Kennzeichnungsrecht
    </span>
    <p className="ImpressumText">
      Alle innerhalb des Internetangebotes genannten und ggf. durch Dritte
      geschützten Marken- und Warenzeichen unterliegen uneingeschränkt den
      Bestimmungen des jeweils gültigen Kennzeichenrechts und den Besitzrechten
      der jeweiligen eingetragenen Eigentümer. Allein aufgrund der bloßen
      Nennung ist nicht der Schluß zu ziehen, dass Markenzeichen nicht durch
      Rechte Dritter geschützt sind! Das Copyright für veröffentlichte, vom
      Autor selbst erstellte Objekte bleibt allein beim Autor der Seiten. Eine
      Vervielfältigung oder Verwendung solcher Grafiken, Tondokumente,
      Videosequenzen und Texte in anderen elektronischen oder gedruckten
      Publikationen ist ohne ausdrückliche Zustimmung des Autors nicht
      gestattet.
    </p>
    <br />
    <span
      style={{
        textDecoration: 'underline',
        paddingBottom: '8px'
      }}
      className="Text"
    >
      4. Einwilligung zur Datennutzung
    </span>
    <p className="ImpressumText">
      4.1. VERANTWORTLICHE STELLE
      <br />
      Verantwortliche Stelle für die Erhebung, Verarbeitung und Nutzung Ihrer
      personenbezogenen Daten im Sinne des Bundesdatenschutzgesetzes ist die
      Fabema Color GmbH, Holderäckerstr. 5, 70839 Gerlingen. Sofern Sie der
      Erhebung, Verarbeitung oder Nutzung Ihrer Daten durch die Fabema Color
      GmbH nach Maßgabe dieser Datenschutzbestimmungen insgesamt oder für
      einzelne Maßnahmen widersprechen wollen, können Sie Ihren Widerspruch per
      E-Mail, Fax oder Brief an folgende Kontaktdaten senden: Fabema Color GmbH,
      Holderäckerstr. 5, 70839 Gerlingen, E-Mail: mail@fabemacolor.de Darüber
      hinaus können Sie natürlich auch jederzeit unentgeltlich Auskunft über die
      bei uns gespeicherten Daten erhalten (sehen Sie hierzu gerne auch Punkt
      4.7).
      <br />
      <br />
      4.2. ERHEBUNG, VERARBEITUNG UND NUTZUNG VON PERSONENBEZOGENEN DATEN
      <br />
      <br />
      4.2.1 PERSONENBEZOGENE DATEN
      <br />
      Personenbezogene Daten sind Angaben über sachliche oder persönliche
      Verhältnisse einer bestimmten oder bestimmbaren natürlichen Person.
      Hierunter fallen beispielsweise Ihr Name, Ihre Telefonnummer, Ihre
      Anschrift, sowie sämtliche Bestandsdaten, die Sie uns beim Anlegen Ihres
      Kundenkontos mitteilen. Statistische Daten, die nicht direkt mit Ihrer
      Person in Personenbezogene Daten sind Angaben über sachliche oder
      persönliche Verhältnisse einer bestimmten oder bestimmbaren natürlichen
      Person. Hierunter fallen beispielsweise Ihr Name, Ihre Telefonnummer, Ihre
      Anschrift, sowie sämtliche Bestandsdaten, die Sie uns beim Anlegen Ihres
      Kundenkontos mitteilen. Statistische Daten, die nicht direkt mit Ihrer
      Person in Verbindung gebracht werden können, fallen nicht
      hierunter.Verbindung gebracht werden können, fallen nicht hierunter.
      <br />
      <br />
      4.2.2 ERHEBUNG, VERARBEITUNG UND NUTZUNG IHRER PERSONENBEZOGENEN DATEN
      <br />
      Datenschutz ist für uns sehr wichtig. Daher halten wir uns bei der
      Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten streng an
      die gesetzlichen Bestimmungen des Bundesdatenschutzgesetzes und des
      Telemediengesetzes. Wir erheben, speichern und verarbeiten Ihre Daten für
      die gesamte Abwicklung Ihres Auftrags, einschließlich eventuell späterer
      Gewährleistungen, für unsere Servicedienste, die technische Administration
      sowie eigene Marketingzwecke. Ihre personenbezogenen Daten werden an
      Dritte nur weitergegeben oder sonst übermittelt, wenn dies zum Zweck der
      Vertragsabwicklung oder Abrechnung erforderlich ist oder Sie zuvor
      eingewilligt haben. Im Rahmen der Bestellabwicklung erhalten
      beispielsweise die hier von uns eingesetzten Dienstleister (wie bspw.
      Transporteur, Logistiker, Banken) die notwendigen Daten zur Bestell- und
      Auftragsabwicklung. Die so weitergegebenen Daten dürfen von unseren
      Dienstleistern lediglich zur Erfüllung ihrer Aufgabe verwendet werden.
      Eine anderweitige Nutzung der Informationen ist nicht gestattet und
      erfolgt auch bei keinem der von uns betrauten Dienstleister. Für Ihre
      Bestellung benötigen wir Ihre korrekten Namens-, Adress- und Zahldaten.
      Eine Löschung Ihrer personenbezogenen Daten erfolgt, sofern gesetzliche
      Aufbewahrungspflichten dem nicht entgegenstehen und wenn Sie einen
      Löschungsanspruch geltend gemacht haben, wenn die Daten zur Erfüllung des
      mit der Speicherung verfolgten Zwecks nicht mehr erforderlich sind oder
      wenn ihre Speicherung aus sonstigen gesetzlichen Gründen unzulässig ist.
      <br />
      <br />
      4.2.3 NUTZUNG IHRER DATEN ZU WERBEZWECKEN
      <br />
      Neben der Verarbeitung Ihrer Daten zur Abwicklung Ihres Kaufs bei der
      Fabema Color GmbH, nutzen wir Ihre Daten auch, um mit Ihnen über Ihre
      Bestellungen, bestimmte Produkte oder Marketingaktionen zu kommunizieren
      und Ihnen Produkte oder Dienstleistungen zu empfehlen, die Sie
      interessieren könnten. Der Verwendung Ihrer personenbezogenen Daten für
      Werbezwecke können Sie jederzeit insgesamt oder für einzelne Maßnahmen
      widersprechen, ohne dass hierfür andere als die Übermittlungskosten nach
      den Basistarifen entstehen. Eine Mitteilung in Textform an die unter
      Ziffer 1 genannten Kontaktdaten (z.B. E-Mail, Fax, Brief) reicht hierfür
      aus.
      <br />
      <br />
      4.2.3.1 NEWSLETTER
      <br />
      Für den Versand des Newsletters verwenden wir das sogenannte Double
      Opt-In-Verfahren, d.h., wir werden Ihnen erst dann einen Newsletter per
      E-Mail zusenden, wenn Sie uns zuvor ausdrücklich bestätigt haben, dass wir
      den Newsletter-Dienst aktivieren sollen. Wir werden Ihnen dann eine
      Benachrichtigungs-E-Mail zusenden und Sie bitten, durch das Anklicken
      eines in dieser E-Mail enthaltenen Links zu bestätigen, dass Sie unseren
      Newsletter erhalten möchten. Sollten Sie später keine Newsletter mehr von
      uns erhalten wollen, können Sie diesem jederzeit widersprechen, ohne dass
      hierfür andere als die Übermittlungskosten nach den Basistarifen
      entstehen. Eine Mitteilung in Textform an die unter Ziffer 1 genannten
      Kontaktdaten (z.B. E-Mail, Fax, Brief) reicht hierfür aus.
      Selbstverständlich finden Sie auch in jedem Newsletter einen Abmelde-Link.
      <br />
      <br />
      4.3. LOG-DATEIEN
      <br />
      Bei jedem Zugriff auf die Seiten der Fabema Color GmbH werden
      Nutzungsdaten durch den jeweiligen Internetbrowser übermittelt und in
      Protokolldateien, den sogenannten Server-Logfiles, gespeichert. Die dabei
      gespeicherten Datensätze enthalten die folgenden Daten: Datum und Uhrzeit
      des Abrufs, Name der aufgerufenen Seite, IP-Adresse, Referrer-URL
      (Herkunfts-URL, von der aus Sie auf die Webseiten gekommen sind), die
      übertragene Datenmenge, sowie Produkt und Versions-Informationen des
      verwendeten Browsers. Die IP-Adressen der Nutzer werden nach Beendigung
      der Nutzung gelöscht oder anonymisiert. Bei einer Anonymisierung werden
      die IP-Adressen derart geändert, dass die Einzelangaben über persönliche
      oder sachliche Verhältnisse nicht mehr oder nur mit einem
      unverhältnismäßig großen Aufwand an Zeit, Kosten und Arbeitskraft einer
      bestimmten oder bestimmbaren natürlichen Person zugeordnet werden können.
      <br />
      <br />
      4.4. WEB ANALYSE
      <br />
      <br />
      4.4.1 GOOGLE ANALYTICS Google
      <br />
      Analytics ist eine Dienstleistung von Google Inc., 1600 Amphitheatre
      Parkway, Mountain View, CA 94043, USA („Google“). Google Analytics
      verwendet „Cookies“, also Textdateien, die auf Ihrem Computer gespeichert
      werden und eine Analyse der Nutzung unseres Angebots durch Google
      ermöglicht. Die durch den Cookie erfassten Informationen über die
      Benutzung unserer Webseiten (einschließlich Ihrer IP-Adresse) werden in
      der Regel an einen Server von Google in die USA übertragen und dort
      gespeichert. Wir weisen darauf hin, dass auf unseren Webseiten Google
      Analytics um den Code „gat._anonymizeIp();;“ erweitert wurde, um eine
      anonymisierte Erfassung von IP-Adressen (sog. IP-Masking) zu
      gewährleisten. Ihre IP-Adresse wird daher auf unsere Veranlassung hin von
      Google lediglich in gekürzter Form erfasst, was eine Anonymisierung
      gewährleistet und keine Rückschlüsse auf Ihre Identität zulässt. Im Falle
      der Aktivierung der IP- Anonymisierung auf unseren Webseiten, wird Ihre
      IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union
      oder in anderen Vertragsstaaten des Abkommens über den Europäischen
      Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle
      IP-Adresse an einen Server von Google in den USA übertragen und dort
      gekürzt. Google wird die genannten Informationen verwenden, um Ihre
      Nutzung unserer Webseiten auszuwerten, um Reports über die
      Webseitenaktivitäten für uns zusammenzustellen und um weitere mit der
      Nutzung von Webseiten und Internet verbundene Dienstleistungen an uns zu
      erbringen. Die im Rahmen von Google Analytics von Ihrem Browser
      übermittelte IP-Adresse wird nicht mit anderen Daten von Google
      zusammengeführt. Eine Übertragung dieser Daten durch Google an Dritte
      findet nur aufgrund gesetzlicher Vorschriften oder im Rahmen der
      Auftragsdatenverarbeitung statt. Keinesfalls wird Google ihre Daten mit
      anderen von Google erfassten Daten zusammenbringen. Durch die Nutzung
      dieser Webseiten erklären Sie sich mit der Bearbeitung der über Sie
      erhobenen Daten durch Google und der zuvor beschriebenen Art und Weise der
      Datenverarbeitung sowie dem benannten Zweck einverstanden. Sie können die
      Speicherung der Cookies durch eine entsprechende Einstellung Ihrer
      Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in
      diesem Fall gegebenenfalls nicht sämtliche Funktionen unserer Webseiten
      vollumfänglich werden nutzen können. Sie können darüber hinaus die
      Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung dieser
      Webseiten bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die
      Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem
      folgenden Linkverfügbare Browser-Plugin herunterladen und installieren.
      Nähere Informationen zu Google Analytics und Datenschutz finden Sie unter
      http://tools.google.com/dlpage/gaoptout?hl=de.
      <br />
      <br />
      4.5. SICHERE DATENÜBERTRAGUNG
      <br />
      Ihre persönlichen Daten werden bei uns sicher durch Verschlüsselung
      übertragen. Dies gilt für Ihre Bestellung und auch für das Kundenlogin.
      Wir bedienen uns dabei des Codierungssystems SSL (Secure Socket Layer).
      Desweiteren sichern wir unsere Webseiten und sonstige Systeme durch
      technische und organisatorische Maßnahmen gegen Verlust, Zerstörung,
      Zugriff, Veränderung oder Verbreitung Ihrer Daten durch unbefugte Personen
      ab.
      <br />
      <br />
      4.6. AUSKUNFTSRECHTE DES BETROFFENEN
      <br />
      Nach dem Bundesdatenschutzgesetz haben unsere Kunden u.a. ein Recht auf
      unentgeltliche Auskunft über ihre gespeicherten Daten, sowie ggf. ein
      Recht auf Berichtigung, Sperrung oder Löschung von Daten. Den Schutz Ihrer
      Daten nehmen wir sehr ernst. Um sicherzustellen, dass personenbezogene
      Daten nicht an Dritte herausgegeben werden, richten Sie Ihre Anfrage bitte
      per E-Mail oder per Post unter eindeutiger Identifizierung Ihrer Person
      an: Fabema Color GmbH, Holderäckerstr. 5 Mail: mail@fabemacolor.de
    </p>
    <br />
    <span
      style={{
        textDecoration: 'underline',
        paddingBottom: '8px'
      }}
      className="Text"
    >
      5. Streitbeilegung
    </span>
    <p className="ImpressumText">
      Die Firma erklärt sich nicht zur Teilnahme an Streitbeilegungsverfahren
      vor Verbraucherschlichtungsstellen im Sinne des § 36 Abs. 1
      Verbraucherstreitbeilegungsgesetz (VSBG) bereit. Davon unberührt ist die
      Möglichkeit der Streitbeilegung durch eine Verbraucherschlichtungsstelle
      im Rahmen einer konkreten Streitigkeit bei Zustimmung beider
      Vertragsparteien (§ 37 VSBG).
      <br />
      Plattform der EU zur außergerichtlichen Online-Streitbeilegung:
      <br />
      http://www.ec.europa.eu/consumers/odr/.
      <br />
      Zur Vermeidung eines etwaigen Rechtsstreits zwischen uns und unseren
      Kunden besteht folgende Allgemeine Verbraucherschlichtungsstelle:
    </p>
    <br />
    <span className="ImpressumText">Zentrums für Schlichtung e.V.</span>
    <span className="ImpressumText">Straßburger Str. 8 </span>
    <span className="ImpressumText">77694 Kehl</span>
    <span className="ImpressumText">http://www.verbraucher-schlichter.de/</span>
  </div>
);

class App extends Component {
  render() {
    return (
      <Router>
        <div className="AppContainer">
          <div className="AppTopNav">
            <ul className="TopNavList">
              <li className="TopNavItem">
                <Link to="/">Startseite</Link>
              </li>
              <li className="TopNavItem">
                <Link to="/imprint">Impressum & Datenschutz</Link>
              </li>
            </ul>
          </div>
          <Route exact path="/" component={Content} />
          <Route exact path="/imprint" component={Imprint} />
        </div>
      </Router>
    );
  }
}

export default App;
